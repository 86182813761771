var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "file-card bg-white rounded"
  }, [_c('div', {
    staticClass: "py-8 bg-neutral-100 cursor-pointer w-full text-center flex items-center justify-center",
    on: {
      "click": function click($event) {
        return _vm.handleClick();
      }
    }
  }, [_vm.setIconsFile(_vm.file.mimeType) === 'VIDEO' ? _c('VideoPlay', {
    attrs: {
      "color": "neutral-400"
    }
  }) : _vm.setIconsFile(_vm.file.mimeType) === 'FOLDER' ? _c('File', {
    attrs: {
      "color": "neutral-400",
      "width": "55",
      "height": "55"
    }
  }) : _c('Document', {
    attrs: {
      "color": "neutral-400"
    }
  })], 1), _c('div', {
    staticClass: "py-3 px-4 bg-white w-full border-t flex items-start justify-between gap-3"
  }, [_c('div', {}, [_c('a', {
    staticClass: "inline-block mb-2 title font-medium hover:text-yellow",
    attrs: {
      "href": _vm.file.url,
      "target": "_blank",
      "title": _vm.file.title
    }
  }, [_vm._v(_vm._s(_vm.file.title))]), _vm.isVideo(_vm.file.mimeType) ? _c('p', {
    staticClass: "text-xs text-neutral-400 mb-2 font-bold"
  }, [_vm._v(" " + _vm._s(_vm.getDuration(_vm.file.durationInSeconds)) + " ")]) : _c('p', {
    staticClass: "text-xs text-neutral-400 mb-1 font-medium"
  }, [_vm._v("-")]), _c('p', {
    staticClass: "text-xs text-neutral-400"
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.file.createdDate)) + " ")])]), _vm.option ? _c('div', {
    staticClass: "inline-block -mr-2 relative",
    attrs: {
      "tabindex": _vm.tabindex
    },
    on: {
      "blur": function blur($event) {
        return _vm.handleBlur($event);
      }
    }
  }, [_c('span', {
    staticClass: "cursor-pointer",
    on: {
      "click": function click($event) {
        return _vm.toggleOption();
      }
    }
  }, [_c('EllipsisVertical')], 1), _vm.visibleOption ? _c('ul', {
    staticClass: "bg-white option rounded-lg p-4 absolute right-0 outline-none"
  }, [_c('li', {
    staticClass: "whitespace-nowrap text-neutral-500 font-medium text-xs mb-2 cursor-pointer",
    on: {
      "click": function click($event) {
        return _vm.showDetails();
      }
    }
  }, [_vm._v("View Details")]), _c('li', {
    staticClass: "whitespace-nowrap text-neutral-500 font-medium text-xs mb-2 cursor-pointer",
    on: {
      "click": function click($event) {
        return _vm.moveTo();
      }
    }
  }, [_vm._v("Move To")]), _c('li', {
    staticClass: "text-neutral-500 font-medium text-xs mb-2 cursor-pointer",
    on: {
      "click": function click($event) {
        return _vm.doCopy(_vm.file.url);
      }
    }
  }, [_vm._v("Copy Link")]), _c('li', {
    staticClass: "text-system-error font-medium text-xs cursor-pointer",
    on: {
      "click": function click($event) {
        return _vm.confirm();
      }
    }
  }, [_vm._v("Delete")])]) : _vm._e()]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }