<template>
  <div class="file-card bg-white rounded">
    <div class="py-8 bg-neutral-100 cursor-pointer w-full text-center flex items-center justify-center" @click="handleClick()">
      <VideoPlay color="neutral-400" v-if="setIconsFile(file.mimeType) === 'VIDEO'" />
      <File color="neutral-400" width="55" height="55" v-else-if="setIconsFile(file.mimeType) === 'FOLDER'" />
      <Document color="neutral-400" v-else />
    </div>
    <div class="py-3 px-4 bg-white w-full border-t flex items-start justify-between gap-3">
      <div class="">
        <a :href="file.url" target="_blank" :title="file.title" class="inline-block mb-2 title font-medium hover:text-yellow">{{ file.title }}</a>
        <p class="text-xs text-neutral-400 mb-2 font-bold" v-if="isVideo(file.mimeType)">
          {{ getDuration(file.durationInSeconds) }}
        </p>
        <p v-else class="text-xs text-neutral-400 mb-1 font-medium">-</p>
        <p class="text-xs text-neutral-400">
          {{ formatDate(file.createdDate) }}
        </p>
      </div>

      <div v-if="option" class="inline-block -mr-2 relative" :tabindex="tabindex" @blur="handleBlur($event)">
        <span class="cursor-pointer" @click="toggleOption()">
          <EllipsisVertical />
        </span>
        <ul class="bg-white option rounded-lg p-4 absolute right-0 outline-none" v-if="visibleOption">
          <li class="whitespace-nowrap text-neutral-500 font-medium text-xs mb-2 cursor-pointer" @click="showDetails()">View Details</li>
          <li class="whitespace-nowrap text-neutral-500 font-medium text-xs mb-2 cursor-pointer" @click="moveTo()">Move To</li>
          <li class="text-neutral-500 font-medium text-xs mb-2 cursor-pointer" @click="doCopy(file.url)">Copy Link</li>
          <li class="text-system-error font-medium text-xs cursor-pointer" @click="confirm()">Delete</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { truncate } from '@/utils/string'
import moment from 'moment'
export default {
  filters: {
    truncate
  },
  components: {
    VideoPlay: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/VideoPlay'),
    Document: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/Document'),
    File: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/File'),
    EllipsisVertical: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/EllipsisVertical')
  },
  props: {
    file: Object,
    option: Boolean
  },
  data() {
    return {
      visibleOption: false,
      tabindex: 0
    }
  },
  methods: {
    doCopy(fileUrl) {
      const promise = new Promise((resolve, reject) => {
        var copyText = document.createElement('input')
        copyText.value = fileUrl
        document.body.appendChild(copyText)
        copyText.select()
        copyText.setSelectionRange(0, 99999)
        navigator.clipboard.writeText(copyText.value)
        document.body.removeChild(copyText)
        resolve()
      })
      promise.then(() => {
        this.$emit('copy_success')
      })
    },
    getDuration(totalSeconds) {
      let hours = Math.floor(totalSeconds / 3600)
      totalSeconds %= 3600
      let minutes = Math.floor(totalSeconds / 60)
      let seconds = totalSeconds % 60
      minutes = String(minutes).padStart(2, '0')
      hours = String(hours).padStart(2, '0')
      seconds = String(seconds).padStart(2, '0')
      return hours + ':' + minutes + ':' + seconds
    },
    isVideo(type) {
      return type.includes('video')
    },
    setIconsFile(type) {
      if (type.includes('video')) return 'VIDEO'
      if (type === 'FOLDER') return 'FOLDER'
      return 'DOCUMENT'
    },
    formatDate(date) {
      return moment(date).local().format('DD MMM YYYY HH:mm')
    },
    toggleOption() {
      this.visibleOption = !this.visibleOption
    },
    showDetails() {
      this.$emit('details', this.file)
    },
    moveTo() {
      this.toggleOption()
      this.$emit('moveTo', this.file)
    },
    handleBlur(event) {
      if (!event.currentTarget.contains(event.relatedTarget)) {
        this.visibleOption = false
      } else {
        this.visibleOption = true
      }
    },
    confirm() {
      this.$emit('confirm', this.file)
    },
    handleClick() {
      if (this.file.mimeType === 'FOLDER') return this.$emit('handleClick', this.file)
      window.open(this.file.url, '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
.rounded-lg {
  border-radius: 8px !important;
}
.option {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
}
.file-card {
  box-shadow: 0px 2px 4px rgba(39, 40, 51, 0.12);
}
.px-4 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.py-3 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.p-3 {
  padding: 12px !important;
}
.p-5 {
  padding: 1.25rem !important;
}
.p-4 {
  padding: 1rem !important;
}
p {
  margin: 0;
}
a {
  color: #58595b;
}
button {
  margin: 0 !important;
  --tw-border-opacity: 1;
  border-color: rgba(253, 233, 210, var(--tw-border-opacity)) !important;
  &:hover {
    background: #fde9d2;
  }
}
.title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.25;
  height: 40px;
  word-break: break-all;
}
</style>
